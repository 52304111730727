import React, { ReactElement } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// PrimeReact imports
import "primereact/resources/themes/lara-light-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons

// AgGrid imports
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import "./index.scss";
import Admin from "./pages/admin/Admin";
import {
  Navigate,
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
} from "react-router-dom";
import {
  ProtectAdminRoute,
  ProtectUserRoute,
} from "services/protected-routes.service";
import TokenService from "services/token.service";
import EnvioEmail from "pages/cadastros/envioEmail/EnvioEmail";
import DiagnosticoPage from "pages/diagnostico/Diagnostico";
import * as Sentry from "@sentry/react";

// AG Grid Licence
LicenseManager.setLicenseKey(
  // eslint-disable-next-line max-len
  "CompanyName=BENCHMKT INTELIGENCIA E PESQUISAS DE MERCADO LTDA,LicensedApplication=Plataforma Varejo 360,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021674,ExpiryDate=18_December_2022_[v2]_MTY3MTMyMTYwMDAwMA==0ac60460f0519e795d7b6e2d1e0e4ce8"
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

interface PropsFunction {
  children: ReactElement;
}

Sentry.init({
  dsn: "https://2c771de9e8296fc62a36325a8746e630@o425066.ingest.sentry.io/4506388612579328",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration(),
  ],
  autoSessionTracking: true,
  release: `plataforma@v${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'development' ? 0.0 : 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'development' ? 0.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV !== 'local' && process.env.REACT_APP_ENV !== 'development' ? 0.0 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const CheckIfLogged = ({ children }: PropsFunction): ReactElement => {
  if (!TokenService.getLocalAccessToken()) {
    return children;
  }
  return <Navigate to="/categoria" replace />;
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);


const UsuariosPage = React.lazy(
  () => import("./pages/cadastros/usuarios/UsuariosPage")
);

const EmpresasPage = React.lazy(
  () => import("./pages/cadastros/empresas/EmpresasPage")
);

const CategoriasPage = React.lazy(
  () => import("./pages/cadastros/categorias/Categorias")
);

const RelatoriosPage = React.lazy(
  () => import("./pages/cadastros/relatorios/RelatoriosPage")
);

const PaginasCadPage = React.lazy(
  () => import("./pages/cadastros/paginas/PaginasPage")
);

const ConsultasPage = React.lazy(
  () => import("./pages/cadastros/consultas/ConsultasPage")
);

const FiltrosPage = React.lazy(
  () => import("./pages/cadastros/filtros/FiltrosPage")
);

const ParametrosPage = React.lazy(
  () => import("./pages/cadastros/parametros/ParametrosPage")
);

const LoginPage = React.lazy(() => import("./pages/login/Login"));

const ResetSenhaPage = React.lazy(
  () => import("./pages/reset-senha/Reset-senha")
);

const ReportPage = React.lazy(() => import("./pages/painel/report/Report"));

const CategoriaPage = React.lazy(
  () => import("./pages/painel/seletor/categoria/CategoriaPage")
);

const GeografiaPage = React.lazy(
  () => import("./pages/painel/seletor/geografia/GeografiaPage")
);
const PaginasPage = React.lazy(
  () => import("./pages/painel/seletor/pagina/PaginaPage")
)

const router = sentryCreateBrowserRouter([
  {
    path: "/admin",
    element: (
      <ProtectAdminRoute>
        <Admin />
      </ProtectAdminRoute>
    ),
    children: [
      {
        path: "usuarios",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <UsuariosPage />
          </React.Suspense>
        ),
      },
      {
        path: "empresas",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <EmpresasPage />
          </React.Suspense>
        ),
      },
      {
        path: "categorias",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <CategoriasPage />
          </React.Suspense>
        ),
      },
      {
        path: "relatorios",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <RelatoriosPage />
          </React.Suspense>
        ),
      },
      {
        path: "componentes",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <PaginasCadPage />
          </React.Suspense>
        ),
      },
      {
        path: "consultas",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ConsultasPage />
          </React.Suspense>
        ),
      },
      {
        path: "filtros",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <FiltrosPage />
          </React.Suspense>
        ),
      },
      {
        path: "parametros",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ParametrosPage />
          </React.Suspense>
        ),
      },
      {
        path: "report/:reportId/page/:pageId",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <ReportPage />
          </React.Suspense>
        ),
      },
      {
        path: "disparo-email",
        element: (
          <React.Suspense fallback={<>Carregando</>}>
            <EnvioEmail />
          </React.Suspense>
        ),
      },
      {
        path: "*",
        element: <Navigate replace to="empresas" />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <CheckIfLogged>
        <React.Suspense fallback={<>Carregando</>}>
          <LoginPage />
        </React.Suspense>
      </CheckIfLogged>
    ),
  },
  {
    path: "/diagnostico",
    element: <DiagnosticoPage></DiagnosticoPage>,
  },
  {
    path: "categoria",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>Carregando</>}>
          <CategoriaPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "geografia",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>...</>}>
          <GeografiaPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "pages",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>...</>}>
          <PaginasPage />
        </React.Suspense>
      </ProtectUserRoute>
    )
  },
  {
    path: "reports/:reportId/page/:pageId",
    element: (
      <ProtectUserRoute>
        <React.Suspense fallback={<>...</>}>
          <ReportPage />
        </React.Suspense>
      </ProtectUserRoute>
    ),
  },
  {
    path: "/change-password",
    element: (
      <React.Suspense fallback={<>...</>}>
        <ResetSenhaPage />
      </React.Suspense>
    ),
  },
  {
    path: "/",
    element: <Navigate replace to="/categoria" />,
  },
  {
    path: "*",
    element: <Navigate replace to="/login" />,
  },
]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
