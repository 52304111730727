import React, { ReactElement, useEffect, useRef, useState } from "react";
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as StepsIcon } from './icons/123-passo.svg';
import './HelpCenter.scss';
import { Panel, PanelHeaderTemplateOptions } from "primereact/panel";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
import RankingService from "services/ranking.service";
import { Ranking } from "interfaces/ranking";
import 'quill/dist/quill.snow.css';
import HandleClick from "middleware/handle-click";

interface HelpCenterProps {
  openZendesk: () => void;
  setVisible: (value: boolean) => void;
  visible: boolean;
  data: any;
  userId: number | undefined,
  pageId: any
}

export const HelpCenter: React.FC<HelpCenterProps> = ({ setVisible, visible, openZendesk, data, userId, pageId }) => {
  const [topBarHeight, setTopBarHeight] = useState<string>('')
  const [animationClass, setAnimationClass] = useState<'enter' | 'exit' | null>(null)
  const [videoTitle, setVideoTitle] = useState<string | null>(null)
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [videoVisible, setVideoVisible] = useState<boolean>(false)
  const [stepsTitle, setStepsTitle] = useState<string | null>(null)
  const [stepsUrl, setStepsUrl] = useState<string>('')
  const [stepsVisible, setStepsVisible] = useState<boolean>(false)
  const helpCenterRef = useRef<HTMLDivElement | null>(null)
  const [ranking, setRanking] = useState<Ranking[]>([])

  const outEvent = (): void => {
    setAnimationClass('exit')
    setTimeout(() => {
      setVisible(false)
    }, 500)
  }

  const openVideo = (title: string, url: string): void => {
    setVideoTitle(title)
    setVideoUrl(url)
    setVideoVisible(true)
  }

  const openSteps = (title: string, url: string): void => {
    setStepsTitle(title)
    setStepsUrl(url)
    setStepsVisible(true)
  }

  const handleClickOutside = (event: MouseEvent): void => {
    if (helpCenterRef.current && !helpCenterRef.current.contains(event.target as Node)) {
      HandleClick.logClickEvents("help_center_outside_click", outEvent())
    }
  }

  const insertVote = (title: string, vote: string): void => {
    setRanking((prevRanking) => {
      const index = prevRanking.findIndex(item => item.title === title)

      if(index !== -1) {
        const updatedRanking = [...prevRanking]
        updatedRanking[index].vote = vote
        return updatedRanking
      } else {
        return [...prevRanking, { title, vote, userId, pageId }]
      }
    })
    
    RankingService.vote(userId, pageId, title, vote)

  }

  useEffect(() => {
    const element = document.querySelector('.selector-navbar')
    if (element) {
      const computedStyle = getComputedStyle(element)
      const height = computedStyle.height
      setTopBarHeight(height)
    }

    const reportBody = document.querySelector('.report-body')

    if (visible && !videoVisible && !stepsVisible) {
      document.addEventListener('mousedown', handleClickOutside)
      reportBody?.classList.add('disable-events')
      setAnimationClass('enter')
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      reportBody?.classList.remove('disable-events')
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [visible, videoVisible, stepsVisible])

  useEffect(() => {
    const rankingData = RankingService.getUserPageRanking(userId, pageId)
    rankingData.then((response: any) => {
      setRanking(response.data)
    })
    rankingData.catch((error) => {
      console.error(error)
      setRanking([])
    })
  }, [])

  const cardHeader = (options: PanelHeaderTemplateOptions, title: string): ReactElement => {
    return (
      <div className={options.className} >
        <span>{title}</span>
        {options.togglerElement}
      </div>
    )
  }

  const CardFooter = (title: string): ReactElement => {
    const currentVote = ranking.find(item => item.title === title)?.vote
    return (
      <div className="sidebar-card-footer">
        <span className="sidebar-card-footer-text">Essa informação foi útil?</span>
        <div className="sidebar-card-votes">
          <div className="sidebar-card-vote" onClick={() => insertVote(title, "UP")}>
            <span className={`pi ${currentVote === 'UP' ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'}`} style={{fontSize: '1.3rem'}}></span>
            <span>Foi útil</span>
          </div>
          <div className="sidebar-card-vote" onClick={() => insertVote(title, "DOWN")}>
            <span className={`pi ${currentVote === 'DOWN' ? 'pi-thumbs-down-fill' : 'pi-thumbs-down'}`} style={{fontSize: '1.3rem'}}></span>
            <span>Não foi útil</span>
          </div>
        </div>
      </div>
    )
  }

  const generateCard = (cardData: any): ReactElement => {
    return (
      <Panel className="sidebar-card" id={`${pageId}-${(cardData.id ? cardData.id : 0)}`} collapsed headerTemplate={(options) => cardHeader(options, cardData.title as string)} toggleable>
        <div className="insert-html" dangerouslySetInnerHTML={{ __html: cardData.description }} />
        {(cardData.video.length > 0 || cardData.steps.length > 0) && (
          <div className="sidebar-card-links">
            {cardData.video.length > 0 && (
              <div className="sidebar-card-link video" onClick={() => openVideo(cardData.title, cardData.video)}>
                <div className="sidebar-card-link-icon">
                  <PlayIcon style={{ position: 'absolute', top: 'calc(50% - 14px)', left: 'calc(50% - 13px)' }} />
                </div>
                <p>Assistir ao vídeo</p>
              </div>
            )}
            {cardData.steps.length > 0 && (
              <div className="sidebar-card-link steps" onClick={() => openSteps(cardData.title, cardData.steps)}>
                <div className="sidebar-card-link-icon">
                  <StepsIcon style={{ position: 'absolute', top: 'calc(50% - 7.5px)', left: 'calc(50% - 17.5px)' }} />
                </div>
                <p>Passo a passo</p>
              </div>
            )}
          </div>
        )}
        {(CardFooter(cardData.title as string))}
      </Panel>
    )
  }

  return (
    <>
      {visible && (
        <div ref={helpCenterRef} className={`sidebar ${animationClass}`} style={{ top: topBarHeight, height: `calc(100vh - ${topBarHeight})` }}>
          <span className="pi pi-times sidebar-close-icon" onClick={() => HandleClick.logClickEvents("help_center_x_click", outEvent())} ></span>
          <div className="sidebar-content">
            <header>
              <h3>Quando usar essa página?</h3>
              {(data.start_media.steps.length > 0 || data.start_media.video.length > 0) && (
                <div className="sidebar-header-links">
                  {data.start_media.video.length > 0 && (
                    <div className="sidebar-header-link video" onClick={() => openVideo('Quando usar essa página?', data.start_media.video)}>
                      <div className="sidebar-header-link-icon">
                        <PlayIcon style={{ position: 'absolute', top: 'calc(50% - 14px)', left: 'calc(50% - 13px)' }} />
                      </div>
                      <p>Assistir ao vídeo</p>
                    </div>
                  )}
                  {data.start_media.steps.length > 0 && (
                    <div className="sidebar-header-link steps" onClick={() => openSteps('Quando usar essa página?', data.start_media.steps)}>
                      <div className="sidebar-header-link-icon">
                        <StepsIcon style={{ position: 'absolute', top: 'calc(50% - 7.5px)', left: 'calc(50% - 17.5px)' }} />
                      </div>
                      <p>Passo a passo</p>
                    </div>
                  )}
                </div>
              )}
            </header>
            <div className="sidebar-cards">
              {data.blocks.length > 0 ?
                data.blocks.map(generateCard) :
                <></>
              }
            </div>

            <footer>
              <p>Precisa de mais informações?</p>
              <div className="footer-link" onClick={() => window.open("https://appt.link/v360-atendimento/treinamento")}>
                <span className="pi pi-calendar-plus"></span>
                <span>Agendar Treinamento</span>
              </div>
              <div className="footer-link" onClick={() => openZendesk()}>
                <span className="pi pi-phone"></span>
                <span>Chat</span>
              </div>
            </footer>
          </div>
        </div>
      )}
      <Dialog
        header={videoTitle}
        visible={videoVisible}
        style={{ width: "900px", height: "650px", display: "flex" }}
        onHide={() => {
          setVideoVisible(false)
          setVideoTitle(null)
          setVideoUrl('')
        }}
        draggable={false}
        icons={
          <button className="p-dialog-header-icon p-dialog-header-close p-link">
            <span
              className="pi-external-link external-link-navbar pi"
              style={{ marginRight: "15px", marginBottom: "5px" }}
              onClick={(e) => {
                window.open(videoUrl, "_blank", "noreferrer");
              }}
            ></span>
          </button>
        }
      >
        <div className="container-video">
          {videoUrl ? (
            <ReactPlayer
              key={videoTitle}
              width="100%"
              height="100%"
              url={videoUrl}
              controls
              style={{
                margin: "auto",
              }}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                  },
                },
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </Dialog>
      <Dialog header={stepsTitle} visible={stepsVisible} style={{ width: '60vw' }} resizable={false} draggable={false} onHide={() => {
        setStepsVisible(false)
        setStepsTitle(null)
        setStepsUrl('')
      }}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
          <iframe
            src={stepsUrl}
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Storylane Demo"
          ></iframe>
        </div>
      </Dialog>
    </>
  )
}