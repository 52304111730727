import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Sidebar } from "primereact/sidebar";
import { StyleClass } from 'primereact/styleclass';
import React, { ReactElement, useContext, useRef } from "react";
import { AdminContext } from "./AdminContext";
import './AdminMenu.scss';
import { useNavigate } from "react-router-dom";

type Botao = {titulo: string, link: string, icone: string}

export function AdminMenu (): ReactElement {
    const [visible, setVisible] = useContext(AdminContext);
    const navigate = useNavigate();
    const botoes: Botao[] = [
        {
            titulo: "Empresas",
            link: "empresas",
            icone: "pi pi-fw pi-building"
        },
        {
            titulo: "Usuários",
            link: "usuarios",
            icone: "pi pi-fw pi-users"
        },
        {
            titulo: "Categorias",
            link: "categorias",
            icone: "pi pi-fw pi-database"
        },
        {
            titulo: "Relatórios",
            link: "relatorios",
            icone: "pi pi-fw pi-folder"
        },
        {
            titulo: "Páginas",
            link: "componentes",
            icone: "pi pi-fw pi-copy"
        },
        {
            titulo: "Consultas",
            link: "consultas",
            icone: "pi pi-fw pi-database"
        },
        {
            titulo: "Filtros",
            link: "filtros",
            icone: "pi pi-fw pi-filter"
        },
        {
            titulo: "Parâmetros",
            link: "parametros",
            icone: "pi pi-fw pi-objects-column"
        },
        {
            titulo: "Disparo de email",
            link: "disparo-email",
            icone: "pi pi-fw pi-envelope"
        }
    ]
    const botoesFooter: Botao[] = [
        {
            titulo: "Painel",
            link: "categoria",
            icone: "pi pi-fw pi-desktop"
        },
        {
            titulo: "Classificador",
            link: "classificador",
            icone: ""
        },
        {
            titulo: "Institucional",
            link: "v360",
            icone: ""
        }
    ]

    const getIcon = (link: string, icone: string): ReactElement => {
        if(link === "classificador") {
            return (
                <img className="item-logo" src="/images/classificador.png" width={20} height={20} />
            )
        } else if (link === "v360") {
            return (
                <img className="item-logo" src="/images/logo.svg" width={20} height={20} />
            )
        } else {
            return (<span className={icone} />)
        }
    }

    const goTo = (link: string): void => {
        if(link === 'classificador') {
            window.open('http://classificador.varejo360.com.br', '_blank')?.focus();
        } else if(link === "v360") {
            window.open('https://varejo360.com/', '_blank')?.focus();
        } else {
            navigate(`/${link}`);
        }
    }

    const checkActive = (link: string): boolean => {
        if(link !== 'categoria') {
            return window.location.href.includes(link)
        }
        return window.location.href.includes('categoria') || window.location.href.includes('geografia') || window.location.href.includes('pages') || window.location.href.includes('reports')
    }

    return (
        <>
            <Sidebar
                data-cy="menu-admin"
                visible={visible}
                onHide={() => setVisible(false)}
                content={({ closeIconRef, hide }) => (
                    <div className="side-menu">
                        <div className="side--header">
                            <h1>
                                Menu
                            </h1>
                            <span className="pi pi-times" onClick={() => setVisible(false)}></span>
                        </div>
                        <div>
                        <ul className="side--body">
                            {
                                botoes.map((botao: Botao) => {
                                    return (
                                        <li key={botao.link} onClick={() => {navigate(`/admin/${botao.link}`)}} className={window.location.href.includes(botao.link) ? "active" : ""}>
                                            <span className={botao.icone}>
                                            </span>
                                            <span>{botao.titulo}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <ul className="side--footer">
                        {
                                botoesFooter.map((botao: Botao) => {
                                    return (
                                        <li key={botao.link} onClick={() => {goTo(botao.link)}} className={checkActive(botao.link) ? "active" : ""}>
                                            {
                                                getIcon(botao.link, botao.icone)
                                            }
                                            <span>{botao.titulo}</span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        </div>
                    </div>
                )}
            ></Sidebar>
        </>
    )
}