import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import CategoriasService from "services/categorias.service";
import ArrayUtils from "utils/ArrayUtils";
import UserService from "services/user.service";
import User from "interfaces/user";
import "../SeletorDashboard.scss";
import SelectorSessionCard from "../SelectorSessionCard";
import HandleClick from "middleware/handle-click";

const CategoriaSeletor = ({
  selecionaCategoria,
  showHeader,
  listStyle,
}: any): React.ReactElement => {
  const [categoriasOptions, setCategoriasOptions] = useState<any[]>([]);
  const [categorias, setCategorias] = useState<any>([]);
  const [categoriaSearch, setCategoriaSearch] = useState("");
  const [isCategoriaContratada, setIsCategoriaContratada] = useState(true);
  const [isTodasCategorias, setIsTodasCategorias] = useState(false);
  const [categoriaOrder, setCategoriaOrder] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [loadingExtract, setLoadingExtract] = useState(false);
  const [finishLoadingFilter, setFinishLoadingFilter] = useState(false);
  const [allDemos, setAllDemos] = useState(false);

  const isSortActive = (index: number): string => {
    return categoriaOrder === index ? " active" : "";
  };
  const [userInfo, setUserInfo] = useState<User>();

  const extrairSetores = (categorias: any[], contratadas: boolean): any => {
    try {
      setLoadingExtract(true);
      const categoriasExibir = contratadas
        ? categorias.filter((item) => !item.demo)
        : categorias;
      const setores = [
        ...new Set(categoriasExibir.map((cat) => cat.sector.trim())),
      ]
        .sort((a: any, b: any) => ArrayUtils.sortDefaultAsc(a, b))
        .map((item) => {
          const items: any[] = [];
          categoriasExibir
            .filter((catItem) => catItem.sector.includes(item))
            .map((catItem) => {
              items.push({
                id: catItem.id,
                name: catItem.name ?? "",
                image: catItem.image ?? "",
                demo: catItem.demo ?? false,
                wip: catItem.wip ?? false,
              });
            });
          return {
            title: item,
            items: items,
          };
        });
      return setores.map((item) => {
        item.items = item.items.sort((a, b) =>
          ArrayUtils.ascSortLocalCompare(a, b, "name")
        );
        return item;
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoadingExtract(false);
    }
  };

  const filtrarCategorias = (isContratadas: boolean): void => {
    try {
      setLoadingFilter(true);
      const categoriaslocal = categoriasOptions.filter((item: any) =>
        item.name
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            categoriaSearch
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      );
      setCategorias(extrairSetores(categoriaslocal, isContratadas));
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoadingFilter(false);
      setFinishLoadingFilter(true)
    }
  };

  const getCategorias = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await CategoriasService.getCategoriasPainel();
      setAllDemos(response.data.every((value: any) => value.demo));
      if (response.status === 200) {
        setCategoriasOptions(response.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categorias.length === 0) {
      getCategorias();
    }
    if (!userInfo) {
      UserService.getUserInfo().then((user) => setUserInfo(user));
    }
  }, []);

  const getSkeleton = (): React.ReactElement => {
    return (
      <>
        <div className="setor" data-cy="categoria-loading">
          <div className="setor--header">
            <Skeleton></Skeleton>
          </div>
          <div className="setor--body">
            <div className="item--card">
              <Skeleton></Skeleton>
              <Skeleton height="200px"></Skeleton>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getNoCategotyMessage = (): React.ReactElement => {
    return (
      <>
        <h3> Nenhuma categoria disponível! </h3>
      </>
    );
  };

  useEffect(() => {
    if (categoriasOptions.length > 0){
      setCategorias([]);
      filtrarCategorias(isCategoriaContratada);
    }
  }, [categoriaSearch]);

  useEffect(() => {
    setIsTodasCategorias(!isCategoriaContratada);
    if (isCategoriaContratada && categoriasOptions.length > 0) {
      filtrarCategorias(isCategoriaContratada);
    }
  }, [isCategoriaContratada]);

  useEffect(() => {
    setIsCategoriaContratada(!isTodasCategorias);
    if (isTodasCategorias && categoriasOptions.length > 0) {
      filtrarCategorias(false);
    }
  }, [isTodasCategorias]);

  useEffect(() => {
    if (categoriasOptions.length > 0){
      filtrarCategorias(isCategoriaContratada);
    }
  }, [categoriasOptions]);

  useEffect(() => {
    allDemos ? setIsTodasCategorias(true) : null;
  }, [allDemos]);

  return (
    <>
      <div className="selector-card">
        <div className="selector--body">
          <div className="content">
            {showHeader ? (
              <div className="indicador-acao">
                <span>
                  Para <strong>avançar</strong> selecione uma categoria abaixo.
                </span>
              </div>
            ) : (
              <></>
            )}
            <div className="configs">
              <div>
                <span>
                  <InputText
                    id="busca"
                    placeholder="Busque uma categoria"
                    value={categoriaSearch}
                    onChange={(e) => setCategoriaSearch(e.target.value)}
                    onFocus={(e) =>
                      HandleClick.logClickEvents("categorias_filtro")
                    }
                  />
                </span>
                <>
                    <span data-cy="categorias-contratadas">
                      <Checkbox
                        checked={isCategoriaContratada}
                        inputId="categorias-contratadas"
                        onChange={(e) =>
                          HandleClick.logClickEvents(
                            "categorias_contratadas",
                            () => setIsCategoriaContratada(true)
                          )
                        }
                      ></Checkbox>
                      <label htmlFor="categorias-contratadas">
                        Categorias contratadas
                      </label>
                    </span>
                    <span data-cy="todas-categorias">
                      <Checkbox
                        checked={isTodasCategorias}
                        inputId="todas-categorias"
                        onChange={(e) =>
                          HandleClick.logClickEvents("categorias_todas", () =>
                            setIsTodasCategorias(true)
                          )
                        }
                      ></Checkbox>
                      <label htmlFor="todas-categorias">
                        Todas as categorias
                      </label>
                    </span>
                  </>
              </div>
              <div className="view-control" style={{ display: "none" }}>
                <span
                  className={`customTooltip${isSortActive(0)}`}
                  title="Visualização por setor"
                  onClick={() => setCategoriaOrder(0)}
                >
                  <i className="pi pi-sort-amount-down"></i>
                </span>
                <span
                  style={{
                    borderRight: "1px solid #ced4da",
                    borderLeft: "1px solid #ced4da",
                  }}
                  className={`customTooltip${isSortActive(1)}`}
                  title="Visualização alfabética"
                  onClick={() => setCategoriaOrder(1)}
                >
                  <i className="pi pi-sort-alpha-down"></i>
                </span>
                <span
                  className={`customTooltip${isSortActive(2)}`}
                  title="Visualização em tabela"
                  onClick={() => setCategoriaOrder(2)}
                >
                  <i className="pi pi-table"></i>
                </span>
              </div>
            </div>
            {/* style={{ height: "calc(100vh - 154px)" }} */}
            <div className="items" style={listStyle}>
              {loading || loadingFilter || loadingExtract ? (
                getSkeleton()
              ) : categorias.length === 0 && categoriaSearch.length === 0 && finishLoadingFilter ? (
                getNoCategotyMessage()
              ) : (
                <></>
              )}
              {categorias.length === 0 && categoriaSearch.length > 0 ? (
                <>
                  <div data-cy="filtro-erro-msg">
                    Não existem categorias com o filtro{" "}
                    <strong>{categoriaSearch}</strong>
                  </div>
                  {isCategoriaContratada ? (
                    <>
                      <span data-cy="filtro-erro-sugestao">
                        Selecione <strong>Todas as categorias</strong> para ter
                        acesso a algumas amostras grátis
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              {loading ? (
                <></>
              ) : (
                <SelectorSessionCard
                  itemArray={categorias}
                  selectedItem={selecionaCategoria}
                  prefix={"categorias"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriaSeletor;
