import { create, StateCreator } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type State = {
  loading: boolean;
  loadingCounter: number;
};

type Action = {
  addLoading: () => void;
  removeLoading: () => void;
};

const useLoadingStore = create<State & Action>((set) => ({
  loading: false,
  loadingCounter: 0,
  addLoading: () =>
    set((state: any) => {
      const newState: any = {};
      const newCounter = state.loadingCounter + 1;
      if (newCounter > 0) {
        newState["loading"] = true;
      }
      newState["loadingCounter"] = newCounter;
      return newState;
    }),
  removeLoading: () =>
    set((state: any) => {
      const newState: any = {};
      const newCounter =
        state.loadingCounter === 0
          ? state.loadingCounter
          : state.loadingCounter - 1;
      if (newCounter === 0) {
        newState["loading"] = false;
      }
      newState["loadingCounter"] = newCounter;
      return newState;
    }),
}));

export default useLoadingStore;
