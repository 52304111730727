/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ReactPlayer from "react-player/lazy";
import CategoriaSeletor from "pages/painel/seletor/categoria/Categoria";
import GeografiaSeletor from "pages/painel/seletor/geografia/Geografia";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import GeografiaService from "services/geografia.service";
import ReportService from "services/report.service";
import TokenService from "services/token.service";
import VideosService from "services/videos.service";
import "./ReportNavbar.scss";
import { Dialog } from "primereact/dialog";
import usePageInfoStore from "pages/painel/report/store/PageInfoStore";
import useSelectedParamStore from "pages/painel/report/store/SelectedParamsStore";
import useShowCleanerAlertStore from "pages/painel/report/store/ShowCleanerAlert";
import useFiltrosStore from "pages/painel/report/store/FiltrosStore";
import useFilterSideVisibilityStore from "pages/painel/report/store/FilterSideVisibilityStore";
import HandleClick from "middleware/handle-click";
import PageSelector from "pages/painel/seletor/pagina/Page";
import PageBookmark from "interfaces/pageBookmark";
import PageService from "services/page.service";
import useBookmarkStore from "components/page-bookmark/bookmarkStore";
import * as Sentry from "@sentry/react";
import ToastUtils from "utils/toastUtils";
import { AdminMenu } from "../adminMenu/AdminMenu";
import { AdminContext } from "../adminMenu/AdminContext";
import DotMenu from "./dotMenu/DotMenu";
import DialogLimpezaHashes from "./dialog-limpeza-hashes/DialogLimpezaHashes";
import PageControls from "./page-controls/PageControls";
import MenuAjuda from "./menu-ajuda/MenuAjuda";
import BeamerButton from "./beamer/BeamerButton";
import useNavBarStore from "pages/painel/report/store/NavbarStore";
import { Divider } from "primereact/divider";

const ReportNavbar = ({
  sideBarMarginTop = 50,
  openHelpCenter,
  handleOpenCenterOpen,
}: any): ReactElement => {
  const userInfo = TokenService.getUserInfo();
  const selectedParams = useSelectedParamStore((state: any) => state.params);
  const [selectorMarkers, setSelectorMarkers] = useState<string[]>([]);
  const [itensNav, setItensNav] = useState<any[]>([]);
  const [passosSelecao, setPassosSelecao] = useState<any>(null);
  const opIsOpened = useNavBarStore((state: any) => state.opIsOpened);
  const setOpIsOpened = useNavBarStore((state: any) => state.setIsOpened);
  const [cid, setCid] = useState(null);
  const [gname, setGname] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [exibirAlerta, setExibirAlerta] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<any>(null);
  const [videoTitle, setVideoTitle] = useState<any>(null);

  // react router dom
  const navigate = useNavigate();
  const { reportId, pageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [zendeskOpen, setZendeskOpen] = useState(false);
  const resetPageInfo = usePageInfoStore((state: any) => state.reset);
  const resetSelectedParams = useSelectedParamStore(
    (state: any) => state.reset
  );
  const resetAppliedFilters = useFiltrosStore((state: any) => state.reset);

  // ref dos toasters de erro/sucesso
  const toastRef = useRef<Toast>(null);

  // Refs dos overlays das seleções
  const opCat = useRef<OverlayPanel>(null);
  const opGeo = useRef<OverlayPanel>(null);
  const opNovoRepo = useRef<OverlayPanel>(null);
  const setFilterPageVisible = useFilterSideVisibilityStore(
    (state: any) => state.setVisibility
  );
  const [pageTabIndex, setPageTabIndex] = useState(0);
  const setBookmark = useBookmarkStore((state: any) => state.setBookmark);
  const [adminMenuVisible, setAdminMenuVisible] = useState<boolean>(false);
  const setShowAlertaResetParam = useShowCleanerAlertStore(
    (state: any) => state.setShow
  );

  const getBookmarkByLink = async (): Promise<PageBookmark> => {
    const empty = {
      title: "",
      accountId: 0,
      link: "",
      pageId: 0,
      categoryId: 0,
      geography: "",
      description: "",
    };
    try {
      const link =
        `${window.location.pathname}${window.location.search}`.replaceAll(
          "&",
          "%26"
        );
      const response = await PageService.getBookmarkByLink(link);

      if (response.data.id) {
        return response.data;
      }
      return empty;
    } catch (error) {
      console.error(error);
      return empty;
    }
  };

  const getSelectorMarkers = (): void => {
    if (!reportId) {
      return;
    }
    ReportService.getSelectorMarkers(reportId)
      .then((value): void => {
        window.sessionStorage.setItem(
          "selector-markers",
          JSON.stringify({
            catId: value.data.category[0].id,
            geo: value.data.geography.geography,
            report: value.data.report.id,
          })
        );
        setPassosSelecao([
          value.data.category[0],
          value.data.geography,
          value.data.report,
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSelectorMarkers();
  }, [location]);

  const onPageSelecionado = async (
    e: any,
    page: any,
    new_page = false,
    change_geography = false,
    newSelectors?: any
  ): Promise<void> => {
    e.stopPropagation();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (page.link) {
        if (new_page) {
          window.open(`${window.location.origin}${page.link}`, "_blank");
        } else {
          navigate(page.link);
          navigate(0);
        }
        return;
      }
      let newPath = "";
      if (page.reportId) {
        newPath = `/reports/${page.reportId}/page/${page.id}`;
      } else {
        const response =
          await ReportService.getReportIdByReportTypeIdAndCategoryIdAndGeography(
            page.reportTypeId,
            newSelectors?.cid ?? cid!,
            newSelectors?.gname ?? gname!
          );
        newPath = `/reports/${response.data}/page/${page.id}`;
      }
      if (change_geography) {
        if (window.location.search.includes("hash")) {
          setShowAlertaResetParam(true);
        }
      } else {
        newPath = `${newPath}${window.location.search}`;
      }
      if (new_page) {
        window.open(`${window.location.origin}${newPath}`, "_blank");
      } else {
        navigate(newPath);
        navigate(0);
      }

      opNovoRepo.current?.hide();
    } catch (error) {
      toastRef.current?.show({
        ...ToastUtils.defaultErrorOptions,
        ...{
          detail:
            "Houve um erro ao selecionar um novo painel, tente novamente!",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onGeografiaSelecionado = async (
    e: any,
    geografia: any,
    new_page = false,
    catid?: any
  ): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (!new_page) {
      setGname(geografia.geography);
    }
    try {
      const responseReports = await ReportService.getReportToSelect(
        catid ? catid : cid,
        geografia.geography
      );
      if (responseReports.data) {
        let reportSelected = responseReports.data.filter(
          (item: any) => item.title === passosSelecao[2].title
        );
        if (reportSelected.length == 0) {
          reportSelected = [responseReports.data[0]];
        }
        await onPageSelecionado(
          e,
          { id: pageId, reportTypeId: reportSelected[0].report_type_id },
          new_page,
          true,
          { cid: catid, gname: geografia.geography }
        );
        opGeo.current?.hide();
      }
    } catch (error) {
      setIsLoading(false);
      opGeo.current?.show(e, null);
      toastRef.current?.show({
        ...ToastUtils.defaultErrorOptions,
        ...{
          detail:
            "Houve um erro ao selecionar uma nova geografia, tente novamente!",
        },
      });
      setTimeout(() => {
        navigate({
          pathname: "/relatorio",
          search: `?cid=${searchParams.get("cid")}&cname=${searchParams.get(
            "cname"
          )}&gid=${geografia.id}&gname=${geografia.name}`,
        });
      }, 3000);
      console.error(error);
    }
  };

  const onCategoriaSelecionado = async (
    e: any,
    categoria: any,
    new_page = false
  ): Promise<void> => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (!new_page) {
      setCid(categoria.id);
    }
    try {
      const geografiasResponse = await GeografiaService.getGeografiaByCategoria(
        categoria.id
      );
      if (geografiasResponse.data) {
        const geografia = geografiasResponse.data.filter(
          (item: any) => item.geography === passosSelecao[1].geography
        );
        await onGeografiaSelecionado(e, geografia[0], new_page, categoria.id);
        opCat.current?.hide();
      }
    } catch (error) {
      setIsLoading(false);
      opCat.current?.show(e, null);
      toastRef.current?.show({
        ...ToastUtils.defaultErrorOptions,
        ...{
          detail:
            "Houve um erro ao selecionar uma nova categoria, tente novamente!",
        },
      });
      setTimeout(() => {
        navigate({
          pathname: "/geografia",
          search: `?cid=${categoria.id}&cname=${categoria.name}`,
        });
      }, 3000);
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedParams) {
      window.sessionStorage.setItem(
        "SelectedParamsValue",
        JSON.stringify(selectedParams)
      );
    }
  }, [selectedParams]);

  useEffect(() => {
    if (passosSelecao && passosSelecao.length > 0) {
      setSelectorMarkers([
        passosSelecao[0].name,
        passosSelecao[1].geography,
        passosSelecao[2].title,
      ]);
      setCid(passosSelecao[0].id);
      setGname(passosSelecao[1].geography);
      let tabindex = 7;
      switch (passosSelecao[2].report_type_id) {
        case 1000:
          tabindex = 1;
          break;
        case 1271:
          tabindex = 2;
          break;
        case 1272:
          tabindex = 3;
          break;
        case 1273:
          tabindex = 4;
          break;
        case 1274:
          tabindex = 5;
          break;
        case 1397:
          tabindex = 6;
          break;
        default:
          tabindex = 7;
          break;
      }
      setPageTabIndex(tabindex);
    }
  }, [passosSelecao]);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      let linkedTo = "";
      if (selectorMarkers && selectorMarkers.length > 0) {
        linkedTo = selectorMarkers[2];
      } else if (itensNav && itensNav.length > 0) {
        linkedTo = itensNav[itensNav.length - 1].route;
      }
      try {
        const videoInfo = await VideosService.getVideoInfo(linkedTo);
        if (videoInfo) {
          setVideoUrl(videoInfo.url);
          setVideoTitle(videoInfo.title);
        } else {
          setVideoUrl(null);
          setVideoTitle(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    try {
      getData().then(() => {
        /**/
      });
    } catch (error) {
      console.error(error);
    }
  }, [itensNav, selectorMarkers]);

  try {
    if (userInfo?.permissionCount && userInfo?.permissionCount > 0) {
      const beamerConfig = (window as any)["beamer_config"];
      if (beamerConfig) {
        beamerConfig["user_firstname"] = userInfo?.name?.split(" ")[0];
        beamerConfig["user_lastname"] = `${userInfo?.company?.id}`;
        beamerConfig["user_id"] = userInfo?.id;
        beamerConfig["user_email"] = userInfo?.email || "N/A";
        (window as any)["beamer_config"] = beamerConfig;
        (window as any).Beamer?.init();
      }
    }
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    if (userInfo) {
      Sentry.setUser({ email: userInfo.email, username: userInfo.name });
    }
    getBookmarkByLink().then((value) => {
      setBookmark(value);
    });
    const pathName = window.location.pathname.split("/");
    if (window.location.pathname.includes("/page/38")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1339?hash_page_params=61eec8ff3327f548b8cfd72af3386ec6e8eacbff4b9298b57872ff83585a2ae4`
      );
    } else if (window.location.pathname.includes("/page/43")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1336?hash_page_params=66ae431530bca0e22f36b61613146864d1936bde8e6eb6338462f8a672d2070d`
      );
    } else if (window.location.pathname.includes("/page/41")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1339?hash_page_params=accc191798d3ded76c17f2daa136756a3ad7546515cf6ec3d9051c8e6341f8d2`
      );
    } else if (window.location.pathname.includes("/page/45")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1339?hash_page_params=db4945b6b2f70457405e4ecd1fd2c8402ba40f0f79abd64b49022786f8907d70`
      );
    } else if (window.location.pathname.includes("/page/42")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1383?hash_page_params=3eb68db7d1568cef8a2f12315a327d10e97204324fb14bdd013a167b64f9f045`
      );
    } else if (window.location.pathname.includes("/page/1230")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1334`
      );
    } else if (window.location.pathname.includes("/page/1231")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1329`
      );
    } else if (window.location.pathname.includes("/page/1232")) {
      window.location.replace(
        `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/1330?hash_page_params=9aa254a798b2d2ed2a4360e98b860d9fd1ca660a3c80bd026fa3c239a599c417`
      );
    } else if (window.location.pathname.includes("/page/68")) {
      if (window.location.href.includes("hash_filter")) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const hashFilter = params.get("hash_filter");
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/69?hash_filter=${hashFilter}`
        );
      } else {
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/${pathName[1]}/${pathName[2]}/page/69`
        );
      }
    }
    if (window.location.pathname === "/categoria") {
      setItensNav([{ label: "Categoria", route: "categoria" }]);
    }
    if (window.location.pathname === "/geografia") {
      const categoria = decodeURI(window.location.search)
        .split("cname=")[1]
        .split("&")[0];
      setItensNav([
        { label: categoria, route: "categoria" },
        { label: "Geografia", route: "geografia" },
      ]);
    }
    if (
      window.location.pathname === "/relatorio" ||
      window.location.pathname === "/pages"
    ) {
      const categoria = decodeURI(window.location.search)
        .split("cname=")[1]
        .split("&")[0];
      const geografia = decodeURI(window.location.search).split("gname=")[1];
      setItensNav([
        { label: categoria, route: "categoria" },
        { label: geografia, route: "geografia" },
        {
          label: "Relatório",
          route: `${
            window.location.pathname === "/pages" ? "pages" : "relatorio"
          }`,
        },
      ]);
    }
  }, []);

  const zendeskApi = (...args: any): void => {
    try {
      if (!(window as any).zE) {
        return;
      }
      (window as any).zE.apply(null, args);
    } catch (e) {
      console.error(e);
    }
  };

  const handleHelpVisible = (value: boolean): void => {
    setHelpVisible(value);
  };

  useEffect(() => {
    if (openHelpCenter) {
      HandleClick.logClickEvents("help_center_from_message", () => {
        handleHelpVisible(true);
      });
      handleOpenCenterOpen();
    }
  }, [openHelpCenter]);

  useEffect(() => {
    if ((window as any).zE) {
      // Manipula o estilo do widget com base no estado helpCenter
      const widget = document.querySelector(".zEWidget-webWidget");
      if (widget) {
        if (helpVisible) {
          widget.classList.add("custom-position-left");
        } else {
          widget.classList.remove("custom-position-left");
        }
      }
    }
  }, [helpVisible]);

  useEffect(() => {
    const notificationClick = localStorage.getItem(`${pageId}-notification`);
    if (notificationClick) {
      setExibirAlerta(false);
    }
  }, []);

  const getSelectorMarkersIcon = (index: number): ReactElement => {
    switch (index) {
      case 0:
        return <i key={Math.random()} className="pi pi-th-large default"></i>;
      case 1:
        return <i key={Math.random()} className="pi pi-globe default"></i>;
      default:
        return <i key={Math.random()} className="pi pi-chart-bar default"></i>;
    }
  };

  const inicioItem = (
    <>
      <span
        className="nav--item dash"
        data-cy="seletor-inicio"
        style={{ cursor: "pointer" }}
        onClick={() =>
          HandleClick.logClickEvents("navbar_inicio", () => {
            resetPageInfo();
            resetSelectedParams();
            resetAppliedFilters();
            navigate("/categoria");
            setFilterPageVisible(false);
          })
        }
      >
        Início
      </span>
      <i className="pi pi-angle-right"></i>
    </>
  );

  const selectorSteps = (): ReactElement => {
    return (
      <>
        <div
          className="indicador"
          style={{
            width: "clamp(950px, 100%, 100% - 374px)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {inicioItem}
            {selectorMarkers.map((item: string, index: number) => {
              return (
                <React.Fragment key={Math.random()}>
                  {index > 0 ? (
                    <i key={Math.random()} className="pi pi-angle-right"></i>
                  ) : (
                    <></>
                  )}
                  <span
                    key={Math.random()}
                    className="nav--item dash"
                    id={`seletor-passo-${index}`}
                    data-cy={`seletor-passo-${index}`}
                    onClick={(e) => {
                      switch (index) {
                        case 1:
                          HandleClick.logClickEvents(
                            "navbar_geografia_overlay",
                            () => opGeo.current?.toggle(e)
                          );
                          break;
                        case 2:
                          HandleClick.logClickEvents(
                            "navbar_novo_relatorio_overlay",
                            () => opNovoRepo.current?.toggle(e)
                          );
                          break;
                        default:
                          HandleClick.logClickEvents(
                            "navbar_categorias_overlay",
                            () => opCat.current?.toggle(e)
                          );
                          break;
                      }
                    }}
                  >
                    {getSelectorMarkersIcon(index)}
                    {item}
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const itemsNav = (): ReactElement => {
    return (
      <>
        <div className="indicador">
          {inicioItem}
          {itensNav.map((item: any, index: number) => {
            return (
              <React.Fragment key={Math.random()}>
                {index > 0 ? (
                  <i key={Math.random()} className="pi pi-angle-right"></i>
                ) : (
                  <></>
                )}
                <span
                  key={Math.random()}
                  className={`nav--item ${
                    index < itensNav.length - 1 ? "dash" : ""
                  }`}
                  onClick={() => {
                    if (index === 1) {
                      navigate({
                        pathname: "/geografia",
                        search: `?cid=${searchParams.get(
                          "cid"
                        )}&cname=${searchParams.get(
                          "cname"
                        )}&gid=${searchParams.get(
                          "gid"
                        )}&gname=${searchParams.get("gname")}`,
                      });
                    }
                    if (index === 0) {
                      navigate({
                        pathname: "/categoria",
                        search: `?cid=${searchParams.get(
                          "cid"
                        )}&cname=${searchParams.get("cname")}`,
                      });
                    }
                  }}
                >
                  {getSelectorMarkersIcon(index)}
                  {item.label}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  };

  const goHome = (): void => {
    if (userInfo?.admin) {
      setAdminMenuVisible(true);
    } else {
      navigate("/categoria");
    }
  };

  // Listener do Zendesk para quando usuário fechar
  {
    try {
      if ((window as any).zE) {
        (window as any).zE("messenger:on", "close", () => {
          setZendeskOpen(false);
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <AdminContext.Provider value={[adminMenuVisible, setAdminMenuVisible]}>
        <AdminMenu />
      </AdminContext.Provider>
      {isLoading ? <ProgressBar mode="indeterminate" /> : ""}
      <Toast ref={toastRef}></Toast>
      <DialogLimpezaHashes />
      <div
        className="selector-navbar"
      >
        <img
          alt="logo"
          src="/images/logo.svg"
          style={{ cursor: "pointer" }}
          width={40}
          height={40}
          onClick={() => goHome()}
        />
        {passosSelecao && passosSelecao.length > 0
          ? selectorSteps()
          : itemsNav()}
        {opIsOpened ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "calc(100vh - 75px)",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              top: `${sideBarMarginTop}px`,
            }}
          ></div>
        ) : (
          <></>
        )}
        {passosSelecao ? (
          <>
            <OverlayPanel
              ref={opCat}
              id="overlay_panel_cat"
              style={{
                width: "clamp(1075px, 75vw, 80vw)",
                maxHeight: "75vh",
                overflowY: "auto",
              }}
              className="overlaypanel-demo"
              onShow={() => setOpIsOpened(true)}
              onHide={() => setOpIsOpened(false)}
            >
              <CategoriaSeletor
                selecionaCategoria={onCategoriaSelecionado}
                listStyle={{ height: "calc(75vh - 115px)" }}
              />
            </OverlayPanel>
            <OverlayPanel
              ref={opGeo}
              id="overlay_panel_geo"
              style={{
                width: "clamp(1075px, 75vw, 80vw)",
                maxHeight: "75vh",
                overflowY: "auto",
              }}
              className="overlaypanel-demo"
              onShow={() => setOpIsOpened(true)}
              onHide={() => setOpIsOpened(false)}
            >
              <GeografiaSeletor
                cid={cid}
                selecionaGeografia={onGeografiaSelecionado}
                listStyle={{ height: "calc(75vh - 115px)" }}
              />
            </OverlayPanel>
            <OverlayPanel
              ref={opNovoRepo}
              id="overlay_panel_novo_report"
              style={{
                width: "clamp(1075px, 80vw, 80vw)",
                maxHeight: "75vh",
                overflowY: "hidden",
                minWidth: "1075px",
              }}
              className="overlaypanel-demo"
              onShow={() => setOpIsOpened(true)}
              onHide={() => setOpIsOpened(false)}
            >
              {
                <PageSelector
                  cid={cid}
                  gname={gname}
                  selecionaPagina={onPageSelecionado}
                  listStyle={{ height: "calc(-170px + 75vh)" }}
                  selectorStyle={{}}
                  tabIndex={pageTabIndex}
                />
              }
            </OverlayPanel>
          </>
        ) : (
          <></>
        )}
        <PageControls toastRef={toastRef} />
        <Divider layout="vertical" />
        <span
          style={{
            display: "flex",
            width: "130px",
            justifyContent: "space-between",
          }}
        >
          <BeamerButton />
          <MenuAjuda zendeskApi={zendeskApi} />
          <DotMenu toastRef={toastRef} />
        </span>
        <Dialog
          header={videoTitle}
          visible={visible}
          style={{ width: "900px", height: "650px", display: "flex" }}
          onHide={() => setVisible(false)}
          draggable={false}
          icons={
            <button className="p-dialog-header-icon p-dialog-header-close p-link">
              <span
                className="pi-external-link external-link-navbar pi"
                style={{ marginRight: "15px", marginBottom: "5px" }}
                onClick={(e) => {
                  window.open(videoUrl, "_blank", "noreferrer");
                }}
              ></span>
            </button>
          }
        >
          <div className="container-video">
            {videoUrl ? (
              <ReactPlayer
                key={videoTitle}
                width="100%"
                height="100%"
                url={videoUrl}
                controls
                style={{
                  margin: "auto",
                }}
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                    },
                  },
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default ReportNavbar;
