import { AxiosRequestConfig } from "axios";
import api from "./api";

const getVideoInfo = (linkedTo: string): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("linkedTo", linkedTo);

  return api.get("/video/linkedto", config).then((response) => {
    return response.data;
  });
};

const VideosService = {
  getVideoInfo,
};

export default VideosService;
